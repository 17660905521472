import { Box, Card, Typography, Theme, Avatar, Button, Grid, IconButton, Link } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { GetApp, GitHub, Instagram, LinkedIn, Mail } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import i18next from 'i18next'
import React from 'react'
import { Refs } from '../../App'
import Typer from '../../utils/textAnimation'
import './hero'

export default function Hero(
	props: {
		classes: ClassNameMap,
		cardOptions: { width: number, height: number, boxShadow: number, appBarHeight: number }
		refs: Refs,
		theme: Theme,
		lang: string,
		loading: boolean,
		setLoading: React.Dispatch<React.SetStateAction<boolean>>,
		onHeaderButtonClick: (event: React.MouseEvent | undefined, elementName: string) => void,
	},
): React.ReactElement {
	const classes = props.classes
	const cardOptions = props.cardOptions
	// const refs = props.refs
	const theme = props.theme
	const loading = props.loading
	const lang = props.lang
	const setLoading = props.setLoading
	const onHeaderButtonClick = props.onHeaderButtonClick

	return (
		<Card id='home-card' className={classes.profileDiv}>
			<Box className={classes.profileBoxPhoto}>
				{loading ?
					<Skeleton animation='wave' variant="circle" className={classes.profilePhoto}>
						<Avatar className={classes.profilePhoto} />
					</Skeleton> : ''
				}
				<Avatar alt="Daniel Goncalves" src={`${process.env.PUBLIC_URL}/img/avatar_v2.jpeg`} className={classes.profilePhoto}
					imgProps={{ onLoad: () => { setLoading(false) } }} style={{ display: `${loading ? 'none' : 'inherit'}` }}
				/>
			</Box>
			<Box className={classes.profileTitle} boxShadow={cardOptions.boxShadow}>
				<Typography variant='h4' align='center' color='inherit'>Daniel Gonçalves</Typography>
				<Typography align='center' color='inherit'><Typer theme={theme} dataText={i18next.t('typer.titles', { returnObjects: true })} color={theme.palette.text.primary} /></Typography>
				<Grid container direction="row" justify="center" alignItems="center" >
					<Link href="https://www.linkedin.com/in/daniel-gon-05/" target="_blank" onClick={() => { return }} color="inherit">
						<IconButton color="inherit">
							<LinkedIn fontSize='small' />
						</IconButton>
					</Link>
					<Link href="https://github.com/masterzeus05" target="_blank" onClick={() => { return }} color="inherit">
						<IconButton color="inherit">
							<GitHub fontSize='small' />
						</IconButton>
					</Link>
					<Link href="https://www.instagram.com/danielgon05/" target="_blank" onClick={() => { return }} color="inherit">
						<IconButton color="inherit">
							<Instagram fontSize='small' />
						</IconButton>
					</Link>
				</Grid>
			</Box>
			<Grid justify="center" className={classes.profileButtons} alignItems="center">
				<Link underline={'none'} href={`${process.env.PUBLIC_URL}/docs/CV_${lang.toUpperCase()}.pdf`} target="_blank" onClick={() => { return }} color="inherit" style={{ width: '100%' }}>
					<Button startIcon={<GetApp />} className={classes.fullButton} color='secondary' variant='contained'>{i18next.t('profile.button.download')}</Button>
				</Link>
				<Button startIcon={<Mail />} onClick={(e) => onHeaderButtonClick(e, 'contact')}
					className={classes.fullButton} color='primary' variant='contained'
				>{i18next.t('profile.button.contact')}</Button>
			</Grid>
		</Card>
	)
}
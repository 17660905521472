import { Box, Card, Chip, Typography, CardContent, List, ListItem, Divider, Theme, Button, Grid, IconButton, Link } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { GetApp, GitHub, Instagram, LinkedIn, Mail } from '@material-ui/icons'
import i18next from 'i18next'
import React from 'react'
import { Refs } from '../../App'
import './contact'

export default function Contact(
	props: {
		classes: ClassNameMap,
		cardOptions: { width: number, height: number, boxShadow: number, appBarHeight: number }
		refs: Refs,
		theme: Theme,
		lang: string,
	},
): React.ReactElement {
	const classes = props.classes
	// const cardOptions = props.cardOptions
	const refs = props.refs
	const theme = props.theme
	const lang = props.lang

	return (
		<Box id='contact' className={classes.section}>
			<Card ref={refs.sections.contact}>
				<CardContent>
					<Box style={{ marginBottom: theme.spacing(1) }} className={classes.stickyTitle + ' stickyContainer'}>
						<Chip
							label={<Typography variant='h5' align='left' color='inherit'>{i18next.t('contact.title')}</Typography>}
							icon={<Mail />}
							color="primary"
							variant='outlined'
						/>
					</Box>
					<List>
						<ListItem>
							<Typography variant='body1' align='left' color='inherit'>{i18next.t('contact.data.location.title')}</Typography>
							<span className={classes.grow} style={{ width: '10px' }} />
							<Typography variant='body1' align='left' color='inherit'>{i18next.t('contact.data.location.content')}</Typography>
						</ListItem>
						<Divider />
						<ListItem>
							<Typography variant='body1' align='left' color='inherit'>Email</Typography>
							<span className={classes.grow} />
							<Typography variant='body1' align='left' color='inherit'>
								<Link href="mailto:dagoncalves99@gmail.com">dagoncalves99@gmail.com</Link>
							</Typography>
						</ListItem>
						<Divider />
						<ListItem>
							<Typography variant='body1' align='left' color='inherit'>{i18next.t('contact.data.internship.title')}</Typography>
							<span className={classes.grow} />
							<Typography variant='body1' align='left' color='error'>{i18next.t('contact.data.internship.content')}</Typography>
						</ListItem>
						<Divider />
						<ListItem>
							<Typography variant='body1' align='left' color='inherit'>{i18next.t('contact.data.fulltime.title')}</Typography>
							<span className={classes.grow} />
							<Typography variant='body1' align='left' style={{color: '#edb956'}}>{i18next.t('contact.data.fulltime.content')}</Typography>
						</ListItem>
						<Divider />
						<ListItem>
							<Typography variant='body1' align='left' color='inherit'>{i18next.t('contact.data.cv.title')}</Typography>
							<span className={classes.grow} />
							<Link underline={'none'} href={`${process.env.PUBLIC_URL}/docs/CV_${lang.toUpperCase()}.pdf`} target="_blank" onClick={() => { return }}>
								<Button startIcon={<GetApp />} variant={'outlined'} style={{ textTransform: 'none' }}>
									<Typography variant='body1' align='left' color='inherit'>{i18next.t('contact.data.cv.content')}</Typography>
								</Button>
							</Link>
						</ListItem>
					</List>
					<Grid container direction="row" justify="center" alignItems="center" >
						<Link href="https://www.linkedin.com/in/daniel-gon-05/" target="_blank" onClick={() => { return }} color="inherit">
							<IconButton color="inherit">
								<LinkedIn fontSize='small' />
							</IconButton>
						</Link>
						<Link href="https://github.com/masterzeus05" target="_blank" onClick={() => { return }} color="inherit">
							<IconButton color="inherit">
								<GitHub fontSize='small' />
							</IconButton>
						</Link>
						<Link href="https://www.instagram.com/danielgon05/" target="_blank" onClick={() => { return }} color="inherit">
							<IconButton color="inherit">
								<Instagram fontSize='small' />
							</IconButton>
						</Link>
					</Grid>
				</CardContent>
			</Card>
		</Box>
	)
}